define("ember-svg-jar/inlined/tripadvisor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.984 10.474a1.624 1.624 0 01-1.623-1.621 1.622 1.622 0 011.623-1.622 1.622 1.622 0 010 3.245zm0-4.094a2.476 2.476 0 00-2.474 2.47 2.476 2.476 0 002.474 2.474 2.476 2.476 0 002.471-2.474 2.475 2.475 0 00-2.471-2.472zM4.95 10.474A1.625 1.625 0 013.327 8.85a1.622 1.622 0 111.623 1.623zm0-4.095A2.477 2.477 0 002.475 8.85a2.478 2.478 0 002.475 2.474 2.475 2.475 0 000-4.947zm8.309-2.164a4.997 4.997 0 00-3.256 4.317 5.01 5.01 0 00-4.89-4.64c1.434-.613 3.08-.946 4.867-.946 1.796 0 3.42.325 4.853.967a4.95 4.95 0 00-1.575.303zm-8.246 8.68a4 4 0 01-4-4A4.002 4.002 0 015.011 4.9a4.002 4.002 0 013.997 3.998 4 4 0 01-3.997 3.996zm8.306-.35a3.967 3.967 0 01-2.076-2.244 3.972 3.972 0 01.118-3.059 3.968 3.968 0 012.247-2.078 4.003 4.003 0 015.134 2.367 4.005 4.005 0 01-2.365 5.135c-1 .37-2.088.327-3.058-.122zm5.693-6.614c.237-1 .985-2.003.985-2.003l-3.341-.002c-1.874-1.211-4.152-1.853-6.677-1.853-2.616 0-4.978.657-6.843 1.878H0s.739.99.981 1.986a4.975 4.975 0 00-.978 2.96 5.014 5.014 0 005.009 5.009 4.998 4.998 0 003.905-1.88l1.067 1.596 1.074-1.609a4.983 4.983 0 001.837 1.45 4.98 4.98 0 003.834.153 5.017 5.017 0 002.963-6.435 4.984 4.984 0 00-.678-1.25zM14.15 8.854a.832.832 0 101.665 0 .832.832 0 00-1.665-.001zm-10.035 0a.833.833 0 101.667 0 .833.833 0 00-1.667 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 16"
    }
  };
});
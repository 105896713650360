define("ember-svg-jar/inlined/smily", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.786 6.693a.212.212 0 01-.095-.022l-3.02-1.484-3.02 1.484a.214.214 0 01-.244-.345l3.114-3.061a.213.213 0 01.3 0l3.115 3.061a.214.214 0 01-.15.367zm-15.572 0a.214.214 0 01-.15-.367l3.114-3.061a.213.213 0 01.3 0l3.115 3.061a.214.214 0 01-.244.345l-3.02-1.484L.309 6.67a.215.215 0 01-.095.022zm7.839 6.104a5.202 5.202 0 01-2.794-.802 4.37 4.37 0 01-1.823-2.39.214.214 0 01.379-.19c.998 1.403 2.525 2.207 4.19 2.207 1.666 0 3.19-.804 4.18-2.206a.214.214 0 01.38.186 4.332 4.332 0 01-1.778 2.406c-.786.516-1.731.789-2.734.789z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});
define("ember-svg-jar/inlined/expedia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.821 6.31A8 8 0 01.357 10.37l7.005-1.617 2.766-.781.232.985-.414 4.757.511-.168 1.963-6.223 2.95-.829c.125-.04.251-.09.373-.147l.078-.038zM8 0c3.456 0 6.4 2.191 7.518 5.26a3.525 3.525 0 00-.49.08l-2.937.83-4.997-4.265-.525.132 2.913 3.82.342.95-2.78.78-6.81 2.336A8 8 0 018 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});
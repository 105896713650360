define("ember-custom-actions/index", ["exports", "ember-custom-actions/actions/resource", "ember-custom-actions/actions/model", "ember-custom-actions/actions/custom"], function (_exports, _resource, _model, _custom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "customAction", {
    enumerable: true,
    get: function () {
      return _custom.default;
    }
  });
  Object.defineProperty(_exports, "modelAction", {
    enumerable: true,
    get: function () {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "resourceAction", {
    enumerable: true,
    get: function () {
      return _resource.default;
    }
  });
});
define("ember-svg-jar/inlined/homeaway", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.28 1.75L2.125 5.579v5.69l2.993-.44s.558-.101.624.44c.033.61-.493.61-.493.61l-3.124.542v1.186l6.642 1.253V5.747c.033 0-3.485-3.996-3.485-3.996zM11.595.668S15.967 5.714 16 5.747l-1.545.136v8.13l-5.656 1.32-7.2-1.355v-1.49l-1.02.17s-.427 0-.559-.44a.554.554 0 01.427-.712l1.151-.136V5.747L.086 5.85 4.36.667h7.234zm-1.48 11.245l1.579-.27V9.506l-1.579.204zm3.519-.643V9.202l-1.316.204v2.1zm-1.283-2.54s1.283-.17 1.315-.17V6.493l-1.315.135v2.1zM6.76 5.916c-.361-.44-.854-.711-1.413-.711h-.099c-.559.033-1.02.338-1.348.779-.329.44-.526 1.05-.526 1.693v.17c.033.677.263 1.287.625 1.693.361.44.855.712 1.413.712h.1c.558-.034 1.018-.34 1.347-.78.329-.44.526-1.05.526-1.693v-.17c-.033-.643-.263-1.253-.624-1.693zm-.23 3.32c-.263.372-.657.609-1.052.609h-.066c-.427 0-.822-.203-1.118-.576a2.362 2.362 0 01-.526-1.456v-.136c0-.576.165-1.084.46-1.456.264-.373.658-.61 1.053-.61h.066c.427 0 .822.203 1.118.576.296.372.526.88.526 1.456v.136c0 .576-.165 1.084-.46 1.456zM4.69 7.78c-.034-.711.196-1.355.525-1.592-.592.034-1.052.779-.986 1.626.033.88.559 1.558 1.15 1.524-.361-.203-.657-.813-.69-1.558zm5.424-.949v2.202l1.579-.203V6.696l-1.579.135z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});
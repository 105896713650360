define("ember-svg-jar/inlined/booking-com", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.13 7.67c-.117-.06-.225-.127-.35-.196.082-.071.151-.13.221-.188a4.37 4.37 0 00.713-.705c.617-.804.854-1.713.799-2.706-.078-1.426-.75-2.49-2.016-3.19C7.615.195 6.644.016 5.641.007a253.985 253.985 0 00-3.967 0C1.16.006.674.24.36.64c-.174.23-.296.494-.36.773v14.43h5.67a7.225 7.225 0 002.322-.362c1.105-.373 2.017-.997 2.597-2.02.58-1.023.7-2.104.46-3.229-.24-1.125-.867-2.01-1.92-2.563zM3.101 4.913c0-.433-.01-.867 0-1.3.02-.63.316-.959.953-.99a9.463 9.463 0 011.854.028c.766.115 1.267.584 1.414 1.33.13.654.042 1.275-.385 1.815-.343.433-.823.6-1.362.606-.783.01-1.566 0-2.35 0-.11 0-.134-.032-.133-.136.013-.445.01-.904.01-1.355zm4.41 7.522c-.39.49-.933.697-1.544.71-.916.018-1.834 0-2.75.007-.1 0-.117-.032-.117-.115v-1.634c0-.519-.01-1.033 0-1.558.016-.647.372-.978 1.026-.978.583 0 1.158-.01 1.736 0 .742.017 1.37.27 1.779.918.483.752.421 1.957-.13 2.648v.002zM14.05 16c-1.03.037-1.95-.83-1.947-1.913.002-1.059.875-1.916 1.952-1.914 1.076.001 1.947.86 1.945 1.92 0 1.024-.877 1.941-1.95 1.907z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});